<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <rect
      x=".76"
      y="12.65"
      width="11.37"
      height="1.24"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <path
      d="m9.9,6.96c-.27-1.36-1.46-2.34-2.85-2.34h-1.21c-1.39,0-2.58.98-2.85,2.34l-1.11,5.69h9.12l-1.11-5.69Z"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <path
      d="m7.91,10.24c-.11-.58-.62-.99-1.21-.99h-.51c-.59,0-1.09.42-1.21.99l-.47,2.41h3.87l-.47-2.41Z"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="6.45"
      y1="2.62"
      x2="6.45"
      y2=".94"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="3.67"
      y1="3.06"
      x2="3.16"
      y2="1.92"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="9.23"
      y1="3.06"
      x2="9.75"
      y2="1.92"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
